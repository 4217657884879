import React from 'react';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import ReactDOM from 'react-dom';
import 'amfe-flexible';
import { MuiThemeProvider } from '@material-ui/core/styles';
import theme from './utils/theme';
import App from './App';
import './index.css';
import rootReducer from './store/reducers';
import MessageBox from './components/MessageBox';

export const store = createStore(rootReducer);

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <Provider store={store}>
      <App />
      <MessageBox />
    </Provider>
  </MuiThemeProvider>,
  document.getElementById('root')
);
