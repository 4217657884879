import { createMuiTheme } from '@material-ui/core/styles';

const htmlFontSize = 75;

const theme = createMuiTheme({
  typography: {
    htmlFontSize,
    h3: {
      fontSize: '0.42667rem',
      lineHeight: 1,
      fontWeight: 500
    },
    button: {
      fontSize: '0.42667rem',
      lineHeight: 1,
      padding: '0.42667rem'
    },
    body1: {
      fontSize: '0.26667rem',
      lineHeight: 1
    },
    subtitle1: {
      fontSize: '0.48rem',
      lineHeight: 1,
      fontFamily: 'din-bold, "Roboto", "Helvetica", "Arial", sans-serif'
    },
    subtitle2: {
      fontSize: '0.42667rem',
      lineHeight: 1,
      fontFamily: 'din-bold, "Roboto", "Helvetica", "Arial", sans-serif'
    }
  },
  palette: {
    primary: {
      main: '#4A7DFA'
    },
    text: {
      secondary: '#666'
    }
  }
});

export default theme;
