import { NetAction, NetInfo } from '../store.d';

const initialState: NetInfo = {
  curCalc: null,
  curDiff: null,
  totalCount: null,
  aveTime: null
};

export default function netInfo(state = initialState, action: NetAction) {
  const { data } = action;
  switch (action.type) {
    case 'UPDATE_INFO': {
      const newState = {
        ...state,
        ...data
      };
      return newState;
    }
    default:
      return state;
  }
}
