import React, { ReactElement, CSSProperties } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

interface Props {
  title?: string;
  children: ReactElement;
  style?: CSSProperties;
  renderHeader?: {
    (): ReactElement;
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  block: {
    padding: `0 ${theme.typography.pxToRem(30)} ${theme.typography.pxToRem(72)}`
  },
  blockHead: {
    position: 'relative',
    marginBottom: theme.typography.pxToRem(40),
    color: '#333'
  }
}));

export default function Block(props: Props) {
  const classes = useStyles();
  const { style, renderHeader, title, children } = props;
  return (
    <div className={classes.block} style={style || undefined}>
      <div className={classes.blockHead}>
        {renderHeader ? (
          renderHeader()
        ) : (
          <Typography variant="h3">{title}</Typography>
        )}
      </div>
      {children}
    </div>
  );
}
