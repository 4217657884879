import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { BaseInfoItem } from '@/components/BaseInfo';
import { useDataApi } from '@/api';
import AccountHeader from './components/AccountHeader';
import MyCalc from './components/MyCalc';
import Records from './components/Records';
import { formatHash, formatNumber } from '@/utils';
import MinerInfo from './components/MinerInfo';
import { MinerInfo as MinerInfoType } from '@/store/store.d';

interface Props {
  miner: MinerInfoType;
}

export default function AccountView(props: Props) {
  const userInfo = useSelector((state: any) => state.userInfo);

  const [baseInfoList, setBaseInfoList] = useState<BaseInfoItem[]>([
    {
      label: '当前算力',
      key: 'currentHash',
      value: null
    },
    {
      label: '24h平均算力',
      key: 'avgHash',
      value: null
    },
    {
      label: '昨日收益',
      key: 'yesterdayReward',
      value: null
    },
    {
      label: '累计收益',
      key: 'totalReward',
      value: null
    }
  ]);

  const [{ data, isLoading }, setParams] = useDataApi(
    {
      method: 'post',
      url: '/ims/account/hashInfo',
      data: {
        accountId: props.miner.id,
        accountName: props.miner.name
      },
      cancelFetch: true
    },
    undefined
  );

  useEffect(() => {
    setParams((oldParams) => ({
      ...oldParams,
      data: {
        accountId: props.miner.id,
        accountName: props.miner.name
      },
      cancelFetch: false
    }));
  }, [props.miner]);

  useEffect(() => {
    console.log(data);
    if (data) {
      const { currentHash, avgHash, yesterdayReward, totalReward } = data;
      setBaseInfoList((oldBaseInfo) => {
        const tempArr = oldBaseInfo.slice();
        tempArr[0].value = formatHash(currentHash);
        tempArr[1].value = formatHash(avgHash);
        tempArr[2].value = formatNumber(yesterdayReward, 8);
        tempArr[3].value = formatNumber(totalReward, 8);
        return tempArr;
      });
    }
  }, [data]);

  return (
    <>
      <AccountHeader />
      <MinerInfo list={baseInfoList} />
      <MyCalc data={data ? data.hashLists : []} isLoading={isLoading} />
      <Records />
    </>
  );
}
