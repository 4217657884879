import { combineReducers } from 'redux';
import netInfo from './netInfo';
import userInfo from './userInfo';
import message from './message';

export default combineReducers({
  netInfo,
  userInfo,
  message
});
