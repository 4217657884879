import React, { useRef, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Typography } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { Chart } from '@antv/g2';
import Block from './Block';
import clsx from 'clsx';
import { formatDiff } from '../utils';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';

interface DataItem {
  hashrate: number;
  refuseRate: number;
  timestamps: string;
}

interface Props {
  data: DataItem[];
  isLoading: boolean;
}

const colorMap = {
  orange: '#FF5B24',
  blue: '#4A7DFA'
};

const useStyles = makeStyles((theme: Theme) => ({
  chartContainer: {
    position: 'relative',
    width: '100%',
    height: theme.typography.pxToRem(572),
    padding: theme.typography.pxToRem(30),
    textAlign: 'center',
    userSelect: 'none',
    WebkitUserSelect: 'none',
    borderRadius: 3,
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      left: '-50%',
      top: '-50%',
      width: '200%',
      height: '200%',
      border: '1px solid #F2F2F2',
      transform: 'scale(0.5)',
      borderRadius: 6
    }
  },
  chartToolbar: {
    position: 'absolute',
    top: theme.typography.pxToRem(-12),
    display: 'flex',
    right: 0,
    backgroundColor: '#FAFAFC',
    borderRadius: theme.typography.pxToRem(8),
    '&:before': {
      boxSizing: 'border-box',
      content: '""',
      display: 'block',
      position: 'absolute',
      left: '-50%',
      top: '-50%',
      width: '200%',
      height: '200%',
      border: '1px solid #F2F2F2',
      transform: 'scale(0.5)',
      borderRadius: theme.typography.pxToRem(16)
    }
  },
  chartToolBtn: {
    position: 'relative',
    fontSize: theme.typography.pxToRem(26),
    width: theme.typography.pxToRem(120),
    height: theme.typography.pxToRem(56),
    transition: 'color 0.3s ease-in',
    border: 0,
    // lineHeight: theme.typography.pxToRem(56),
    '&.active': {
      color: '#fff'
    }
  },
  btnMask: {
    position: 'absolute',
    top: theme.typography.pxToRem(4),
    bottom: theme.typography.pxToRem(4),
    left: theme.typography.pxToRem(4),
    width: theme.typography.pxToRem(112),
    backgroundColor: theme.palette.primary.main,
    transition: 'transform 0.3s ease-in',
    borderRadius: theme.typography.pxToRem(8)
  },
  show: {
    display: 'block'
  },
  chartArea: {
    display: 'none',
    width: '100%',
    height: '100%',
    '&$show': {
      display: 'block'
    }
  },
  axisTitle: {
    position: 'absolute',
    top: theme.typography.pxToRem(60),
    left: 0,
    fontSize: theme.typography.pxToRem(28),
    fontFamily: '"Roboto"'
  }
}));

function PoolCalc(props: Props) {
  const classes = useStyles();
  const theme = useTheme();
  const hashRef = useRef(null);
  const refuseRef = useRef(null);
  const { data, isLoading } = props;

  const [chartType, setChartType] = useState(1);

  useEffect(() => {
    const chart = new Chart({
      container: ReactDOM.findDOMNode(
        (chartType === 1 ? hashRef : refuseRef).current
      ) as HTMLDivElement,
      autoFit: true,
      padding: [50, 0, 30, 30]
    });
    chart.data(data);

    if (chartType === 1) {
      chart.scale({
        hashrate: {
          min: 0,
          nice: true,
          tickCount: 5
        },
        timestamps: {
          type: 'time',
          mask: 'YYYY-MM-DD HH:mm:ss'
        }
      });
      chart.axis('hashrate', {
        label: {
          formatter: (text: string) => {
            return Math.round(Number(text) / 1000000000);
          }
        }
      });
      chart
        .area()
        .position('timestamps*hashrate')
        .color(colorMap.blue)
        .shape('smooth')
        .tooltip(
          'timestamps*hashrate',
          (timestamps: string, hashrate: number) => ({
            title: timestamps,
            name: '算力',
            value: formatDiff(hashrate)
          })
        );
      chart
        .line()
        .position('timestamps*hashrate')
        .color(colorMap.blue)
        .shape('smooth')
        .tooltip(
          'timestamps*hashrate',
          (timestamps: string, hashrate: number) => ({
            title: timestamps,
            name: '算力',
            value: formatDiff(hashrate)
          })
        );
    } else {
      chart.scale({
        refuseRate: {
          max: 100,
          min: 0,
          nice: true
        },
        timestamps: {
          type: 'time',
          mask: 'YYYY-MM-DD HH:mm:ss'
        }
      });
      chart.axis('refuseRate', {});
      chart
        .area()
        .position('timestamps*refuseRate')
        .color(colorMap.orange)
        .shape('smooth')
        .tooltip(
          'timestamps*refuseRate',
          (timestamps: string, refuseRate: number) => {
            return {
              title: timestamps,
              name: '拒绝率',
              value: refuseRate.toFixed(2) + ' %'
            };
          }
        );
      chart
        .line()
        .position('timestamps*refuseRate')
        .color(colorMap.orange)
        .shape('smooth')
        .tooltip(
          'timestamps*refuseRate',
          (timestamps: string, refuseRate: number) => {
            return {
              title: timestamps,
              name: '拒绝率',
              value: refuseRate.toFixed(2) + ' %'
            };
          }
        );
    }

    chart.axis('timestamps', {
      label: {
        formatter: (val: string, item: any, index: number) => {
          return index === 0 ? '' : val.slice(11, 16);
        }
      }
    });

    chart.tooltip({
      showCrosshairs: true
    });

    chart.interaction('active-region');
    chart.removeInteraction('legend-filter'); // 自定义图例，移除默认的分类图例筛选交互
    chart.render();

    return () => chart.destroy();
  }, [data, chartType]);

  return (
    <Block
      renderHeader={() => (
        <>
          <Typography variant="h3">矿池算力</Typography>
          <div className={classes.chartToolbar}>
            <div
              className={classes.btnMask}
              style={{
                transform: `translateX(${
                  chartType === 1 ? 0 : theme.typography.pxToRem(112 + 4)
                })`
              }}
            ></div>
            <button
              className={clsx(classes.chartToolBtn, {
                active: chartType === 1
              })}
              onClick={() => setChartType(1)}
            >
              算力
            </button>
            <button
              className={clsx(classes.chartToolBtn, {
                active: chartType === 2
              })}
              onClick={() => setChartType(2)}
            >
              拒绝率
            </button>
          </div>
        </>
      )}
    >
      <div className={classes.chartContainer}>
        {isLoading ? (
          <Skeleton variant="rect" width={'100%'} height={'100%'} />
        ) : (
          <>
            <div
              className={clsx(
                chartType === 1 ? classes.show : null,
                classes.chartArea
              )}
              ref={hashRef}
            ></div>
            <div
              className={clsx(
                chartType === 2 ? classes.show : null,
                classes.chartArea
              )}
              ref={refuseRef}
            ></div>
            <div
              className={classes.axisTitle}
              style={{
                left: chartType === 1 ? '10px' : '27px'
              }}
            >
              {chartType === 1 ? 'GH/s' : '%'}
            </div>
          </>
        )}
      </div>
    </Block>
  );
}

export default PoolCalc;
