import { NetInfo, MessageInfo, UserInfo, MinerInfo } from './store.d';

export const updateInfo = (data: NetInfo) => ({
  type: 'UPDATE_INFO',
  data
});

export const updateMessage = (data: MessageInfo) => ({
  type: 'UPDATE_MESSAGE_INFO',
  data
});

export const addUser = (data: UserInfo) => ({
  type: 'ADD_USER',
  data
});

export const switchMiner = (data: MinerInfo) => ({
  type: 'SWITCH_MINER',
  data
});

export const logout = () => ({
  type: 'DELETE_USER'
});
