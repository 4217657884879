/**
 * 给数字保留小数
 * @method formatNumber
 * @param {Number} num 需要保留小数的数字
 * @param {Number} psc 保留的精度
 * @returns {Number} 保留后的数字
 */
export function formatNumber(num: number, psc: number = 5) {
  const formatNum = parseFloat(num.toString());
  const decimals = formatNum.toString().split('.')[1] || '';
  return decimals.length > psc ? formatNum.toFixed(psc) : formatNum;
}

export function formatTime(date: number) {
  const newDate = new Date(date * 1000);
  return (
    // [date.getMonth() + 1, date.getDate()].join('-') +
    // ' ' +
    // [date.getHours(), date.getMinutes()].join(':')
    newDate.getMonth() +
    1 +
    '-' +
    newDate.getDate() +
    ' ' +
    newDate.getHours() +
    ':' +
    ('0' + newDate.getMinutes()).slice(-2)
  );
}

export function formatDate(date: Date) {
  return (
    date.getMonth() +
    1 +
    '-' +
    date.getDate() +
    ' ' +
    date.getHours() +
    ':' +
    date.getMinutes()
  );
}

export function formatDiff(difficulty: number, digit: number = 2) {
  const diffi = difficulty.toString();

  if (diffi.length < 4) {
    return `${difficulty} H/s`;
  }

  if (diffi.length < 7) {
    return `${parseFloat((difficulty / Math.pow(10, 3)).toFixed(digit))} KH/s`;
  }

  if (diffi.length < 10) {
    return `${parseFloat((difficulty / Math.pow(10, 6)).toFixed(digit))} MH/s`;
  }

  if (diffi.length < 13) {
    return `${parseFloat((difficulty / Math.pow(10, 9)).toFixed(digit))} GH/s`;
  }

  if (diffi.length < 16) {
    return `${parseFloat((difficulty / Math.pow(10, 12)).toFixed(digit))} TH/s`;
  }

  return `${(difficulty / Math.pow(10, 15)).toFixed(digit)} PH/s`;
}

export function formatHash(hashes: number = 0) {
  var result = 0;
  var unit = 'K';

  if (hashes !== 0 && hashes < 1000) {
    result = hashes;
    unit = '';
  }

  if (hashes >= 1000 && hashes < Math.pow(1000, 2)) {
    result = hashes / 1000;
    unit = 'K';
  }

  if (hashes >= Math.pow(1000, 2) && hashes < Math.pow(1000, 3)) {
    result = hashes / Math.pow(1000, 2);
    unit = 'M';
  }

  if (hashes >= Math.pow(1000, 3) && hashes < Math.pow(1000, 4)) {
    result = hashes / Math.pow(1000, 3);
    unit = 'G';
  }

  if (hashes >= Math.pow(1000, 4) && hashes < Math.pow(1000, 5)) {
    result = hashes / Math.pow(1000, 4);
    unit = 'T';
  }

  return formatNumber(result, 2) + unit + 'H/s';
}

export function formatPhone(phone: string) {
  return phone.replace(/^(\d{3})\d{4}(\d{4})$/, '$1****$2');
}

// export function makeMyStyles(styles: any) {
//   Object.keys(styles).forEach((key) => {
//     for (const style in styles[key]) {
//       if (
//         typeof styles[key][style] === 'string' &&
//         styles[key][style].includes('px')
//       ) {
//         styles[key][style] =
//           formatNumber(styles[key][style].replace('px', '') / rootValue) +
//           'rem';
//       }
//     }
//   });
//   return makeStyles(styles);
// }
