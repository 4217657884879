import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
// import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { updateMessage } from '@/store/actions';

// function Alert(props: AlertProps) {
//   return <MuiAlert elevation={6} variant="standard" {...props} />;
// }

const useStyles = makeStyles((theme: Theme) => ({
  snackbarContentRoot: {
    flexGrow: 0,
    fontSize: theme.typography.pxToRem(26)
  }
}));

export default function CustomizedSnackbars() {
  const { message, open } = useSelector((state: any) => state.message);
  const dispatch = useDispatch();

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    dispatch(
      updateMessage({
        message: '',
        open: false
      })
    );
  };

  const classes = useStyles();
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={open}
      autoHideDuration={3000}
      onClose={handleClose}
      message={message}
      ContentProps={{
        classes: {
          root: classes.snackbarContentRoot
        }
      }}
    />
  );
}
