import React, { ReactElement } from 'react';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import Block from './Block';

export interface BaseInfoItem {
  label: string;
  key: string;
  value: string | number | null;
  render?: {
    (val: string | number | null, row: BaseInfoItem[]): string;
  };
}

interface Props {
  dataList: BaseInfoItem[];
  renderHeader?: {
    (): ReactElement;
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  baseInfoList: {
    whiteSpace: 'nowrap',
    overflowX: 'auto',
    overflowY: 'hidden',
    WebkitOverflowScrolling: 'touch',
    paddingRight: theme.typography.pxToRem(30)
  },
  baseInfoItem: {
    position: 'relative',
    display: 'inline-block',
    verticalAlign: 'middle',
    width: theme.typography.pxToRem(304),
    height: theme.typography.pxToRem(158),
    paddingTop: theme.typography.pxToRem(44),
    paddingLeft: theme.typography.pxToRem(30),
    borderRadius: 4,
    '& + $baseInfoItem': {
      marginLeft: theme.typography.pxToRem(19)
    },
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      left: '0',
      top: '0',
      width: theme.typography.pxToRem(608),
      height: theme.typography.pxToRem(316),
      border: '1px solid #F2F2F2',
      transform: 'scale(0.48)',
      transformOrigin: 'top left',
      borderRadius: 6
    }
  },
  baseInfoValue: {
    marginTop: theme.typography.pxToRem(25)
  }
}));

function BaseInfo(props: Props) {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <Block
      style={{
        paddingRight: 0,
        paddingTop: theme.typography.pxToRem(72)
      }}
      title="基础信息"
      renderHeader={props.renderHeader}
    >
      <div className={classes.baseInfoList}>
        {props.dataList.map((baseInfo: BaseInfoItem) => (
          <div className={classes.baseInfoItem} key={baseInfo.label}>
            {baseInfo.value === null ? (
              <>
                <Skeleton width="40%" height="0.26667rem" />
                <Skeleton
                  variant="rect"
                  width="80%"
                  height={theme.typography.pxToRem(36)}
                  style={{
                    marginTop: theme.typography.pxToRem(25)
                  }}
                />
              </>
            ) : (
              <>
                <Typography color="textSecondary">{baseInfo.label}</Typography>
                <Typography
                  variant="subtitle1"
                  className={classes.baseInfoValue}
                >
                  {baseInfo.render
                    ? baseInfo.render(baseInfo.value, props.dataList)
                    : baseInfo.value}
                  {/* {baseInfoMap[baseInfo.key as keyof typeof BaseInfo]} */}
                </Typography>
              </>
            )}
          </div>
        ))}
      </div>
    </Block>
  );
}

export default BaseInfo;
