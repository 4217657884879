import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import BaseInfo, { BaseInfoItem } from '@/components/BaseInfo';
import { UserInfo } from '@/store/store.d';
import { switchMiner } from '@/store/actions';

const useStyles = makeStyles((theme: Theme) => ({
  headerRoot: {
    paddingRight: theme.typography.pxToRem(30)
  },
  selectBtn: {
    paddingLeft: 0,
    paddingRight: 0,
    color: '#999',
    fontSize: theme.typography.pxToRem(28)
  },
  selectBtnIcon: {
    fontSize: '12px !important',
    color: '#C7C7CB'
  },
  menuItem: {
    minHeight: 'auto',
    color: '#666',
    fontSize: theme.typography.pxToRem(28)
  }
}));

interface Props {
  list: BaseInfoItem[];
}

export default function MinerInfo(props: Props) {
  const userInfo: UserInfo = useSelector((state: any) => state.userInfo);

  const { curMiner, minerList } = userInfo;

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const dispatch = useDispatch();
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <BaseInfo
      renderHeader={() => (
        <Grid
          container
          direction="row"
          alignItems="center"
          justify="space-between"
          className={classes.headerRoot}
        >
          <Typography variant="h3">当前矿工</Typography>
          <Button
            aria-controls="simple-menu"
            aria-haspopup="true"
            endIcon={
              <ArrowForwardIosIcon
                classes={{
                  root: classes.selectBtnIcon
                }}
              />
            }
            classes={{
              root: classes.selectBtn
            }}
            size="small"
            onClick={handleClick}
          >
            {curMiner?.name}
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {minerList?.map((miner) => (
              <MenuItem
                classes={{
                  root: classes.menuItem
                }}
                onClick={() => {
                  dispatch(switchMiner(miner));
                  handleClose();
                }}
                key={miner.id}
              >
                {miner.name}
              </MenuItem>
            ))}
          </Menu>
        </Grid>
      )}
      dataList={props.list}
    />
  );
}
