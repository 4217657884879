import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Typography, Grid } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import Block from './Block';

export interface RecordItem {
  time: string;
  height: number;
  reward: number;
  fee: number;
  uncleReward: number;
  address: string;
  hash: string;
}

interface Props {
  records: RecordItem[];
  isLoading: boolean;
  loadMore: any;
  addressLabel: string;
}

const windowHeight = window.innerHeight;

const useStyles = makeStyles((theme: Theme) => ({
  recordBlock: {
    position: 'relative',
    display: 'flex',
    flexWrap: 'wrap',
    height: theme.typography.pxToRem(492),
    padding: `${theme.typography.pxToRem(40)} ${theme.typography.pxToRem(30)}`,
    marginBottom: theme.typography.pxToRem(20),
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      left: '-50%',
      top: '-50%',
      width: '200%',
      height: '200%',
      border: '1px solid #F2F2F2',
      transform: 'scale(0.5)',
      borderRadius: 6
    }
  },
  recordItem: {
    width: '33.33333%',
    height: theme.typography.pxToRem(82),
    textAlign: 'center',
    '&:last-child': {
      textAlign: 'right'
    },
    '&:first-child': {
      textAlign: 'left'
    },
    '&:only-child': {
      width: '100%',
      '& $value': {
        wordBreak: 'break-all',
        lineHeight: 1.5
      }
    }
  },
  label: {},
  value: {
    marginTop: theme.typography.pxToRem(34),
    fontWeight: 400
  }
}));

const BlockRecords = (props: Props) => {
  const classes = useStyles();
  const { records, isLoading, loadMore, addressLabel } = props;
  const ref = useRef(null);

  useEffect(() => {
    const scrollFunc = () => {
      if (isLoading) {
        return false;
      }
      const lastRecord = ReactDOM.findDOMNode(ref.current)
        ?.lastChild as HTMLDivElement;
      if (
        lastRecord &&
        window.pageYOffset + windowHeight >
          lastRecord.offsetTop - lastRecord.offsetHeight
      ) {
        loadMore();
      }
    };
    window.addEventListener('scroll', scrollFunc);
    return () => window.removeEventListener('scroll', scrollFunc);
  }, [isLoading, loadMore]);

  return (
    <Block title="出块记录">
      <div ref={ref}>
        {records.map((record, index) => (
          <div className={classes.recordBlock} key={index}>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="flex-start"
            >
              <div className={classes.recordItem}>
                <Typography className={classes.label} color="textSecondary">
                  出块时间
                </Typography>
                <Typography variant="subtitle2" className={classes.value}>
                  {record.time}
                </Typography>
              </div>
              <div className={classes.recordItem}>
                <Typography className={classes.label} color="textSecondary">
                  区块高度
                </Typography>
                <Typography variant="subtitle2" className={classes.value}>
                  {record.height}
                </Typography>
              </div>
              <div className={classes.recordItem}>
                <Typography className={classes.label} color="textSecondary">
                  固定收益
                </Typography>
                <Typography
                  variant="subtitle2"
                  className={classes.value}
                  color="primary"
                >
                  {record.reward}
                </Typography>
              </div>
            </Grid>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="flex-start"
            >
              <div className={classes.recordItem}>
                <Typography className={classes.label} color="textSecondary">
                  手续费收益
                </Typography>
                <Typography variant="subtitle2" className={classes.value}>
                  {record.fee}
                </Typography>
              </div>
              <div className={classes.recordItem}>
                <Typography className={classes.label} color="textSecondary">
                  叔块奖励
                </Typography>
                <Typography variant="subtitle2" className={classes.value}>
                  {record.uncleReward}
                </Typography>
              </div>
              <div className={classes.recordItem}></div>
            </Grid>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="flex-start"
            >
              <div className={classes.recordItem}>
                <Typography className={classes.label} color="textSecondary">
                  {addressLabel}
                </Typography>
                <Typography variant="subtitle2" className={classes.value}>
                  {record.address}
                </Typography>
              </div>
            </Grid>
          </div>
        ))}
        {isLoading ? (
          <>
            <Skeleton
              variant="rect"
              width={'100%'}
              height={'6.56rem'}
              style={{ marginBottom: '0.26667rem' }}
            />
            <Skeleton variant="rect" width={'100%'} height={'6.56rem'} />
          </>
        ) : null}
      </div>
    </Block>
  );
};

export default BlockRecords;
