import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Typography, Grid } from '@material-ui/core';
import ActionButton from '@/components/ActionButton';
import { logout } from '@/store/actions';
import { formatPhone } from '@/utils';

const useStyles = makeStyles((theme: Theme) => ({
  headerRoot: {
    position: 'relative',
    padding: `${theme.typography.pxToRem(40)} ${theme.typography.pxToRem(
      32
    )} ${theme.typography.pxToRem(32)}`,
    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: theme.typography.pxToRem(32),
      right: theme.typography.pxToRem(32),
      height: 0,
      borderBottom: '1px solid #F2F2F2'
    }
  },
  headerWrapper: {
    borderBottom: '1px solid #F2F2F2'
  },
  headerImg: {
    width: theme.typography.pxToRem(88),
    height: theme.typography.pxToRem(88)
  },
  btnStyle: {
    height: theme.typography.pxToRem(56),
    fontSize: '0.37333rem',
    boxShadow: '0 0'
  },
  account: {
    marginLeft: theme.typography.pxToRem(40),
    flex: 1
  }
}));

export default function AccountHeader() {
  const userInfo = useSelector((state: any) => state.userInfo);
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleBtnClick = () => {
    dispatch(logout());
  };

  return (
    <div className={classes.headerRoot}>
      <Grid container direction="row" alignItems="center">
        <img
          className={classes.headerImg}
          src={require('@/assets/images/simpool_icon@2x.png')}
          alt=""
        />
        <Typography
          className={classes.account}
          variant="subtitle1"
          component="span"
        >
          {formatPhone(userInfo.account)}
        </Typography>
        <ActionButton
          fullWidth={false}
          classes={{
            root: classes.btnStyle
          }}
          onClick={handleBtnClick}
        >
          退出
        </ActionButton>
      </Grid>
    </div>
  );
}
