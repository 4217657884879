import React from 'react';
import { useSelector } from 'react-redux';
import LoginView from './LoginView';
import AccountView from './AccountView';

export default function AccountInfo() {
  const userInfo = useSelector((state: any) => state.userInfo);
  const isLogin = userInfo.curMiner;

  return (
    <>{isLogin ? <AccountView miner={userInfo.curMiner} /> : <LoginView />}</>
  );
}
