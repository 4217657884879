import React from 'react';
import clsx from 'clsx';
import Button, { ButtonProps } from '@material-ui/core/Button';
import { withStyles, Theme, createStyles } from '@material-ui/core/styles';

interface Props extends ButtonProps {
  classes: any;
}

function ClassNames(props: Props) {
  const { classes, children, className, ...other } = props;
  return (
    <Button
      fullWidth
      variant="contained"
      className={clsx(classes.root, classes.label, className)}
      {...other}
    >
      {children || 'Button'}
    </Button>
  );
}

export default withStyles((theme: Theme) =>
  createStyles({
    root: {
      height: theme.typography.pxToRem(100),
      borderRadius: theme.typography.pxToRem(8),
      // padding: `${theme.typography.pxToRem(6)} ${theme.typography.pxToRem(8)}`,
      color: 'white',
      backgroundColor: 'rgba(74, 125, 250, 1)',
      '&:hover': {
        backgroundColor: 'rgba(99, 150, 250, 1)'
      }
    }
  })
)(ClassNames);
