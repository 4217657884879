import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { formatDiff, formatHash } from '../utils';
import BaseInfo, { BaseInfoItem } from '@/components/BaseInfo';
import NetCalc from '@/components/NetCalc';
import BlockRecords, { RecordItem } from '@/components/BlockRecords';
import { useDataApi } from '../api';
import DEFAULT_CONFIG from '@/config/config';
import { formatNumber, formatTime } from '../utils';

interface BaseInfo {
  curCalc: string | null;
  curDiff: string | null;
  totalCount: number | null;
  aveTime: string | null;
}

export default function NetInfo() {
  const netInfo = useSelector((state: any) => state.netInfo);
  const [baseInfoList, setBaseInfoList] = useState<BaseInfoItem[]>([
    {
      label: '当前全网算力',
      key: 'curCalc',
      value: null
    },
    {
      label: '当前难度值',
      key: 'curDiff',
      value: null
    },
    {
      label: '区块高度',
      key: 'totalCount',
      value: null
    },
    {
      label: '平均出块时间',
      key: 'aveTime',
      value: null
    }
  ]);
  useEffect(() => {
    const { curCalc, curDiff, totalCount, aveTime } = netInfo;
    setBaseInfoList((oldBaseInfo: BaseInfoItem[]) => {
      const newData = oldBaseInfo.slice();
      newData[0].value = curCalc ? formatHash(curCalc) : null;
      newData[1].value = curDiff ? formatDiff(Number(curDiff)) : null;
      newData[2].value = totalCount;
      newData[3].value = aveTime ? aveTime.toFixed(2) + 's' : null;
      return newData;
    });
  }, [netInfo]);

  const [chartResult] = useDataApi(
    {
      url: DEFAULT_CONFIG.EXPlORER_BASE_URL + '/api/block/chart'
    },
    []
  );

  const page = useRef(1);
  const [records, setRecords] = useState<RecordItem[]>([]);
  const [blockResult, doFetch] = useDataApi(
    {
      url: DEFAULT_CONFIG.EXPlORER_BASE_URL + '/api/block/page',
      params: {
        pageNumber: page.current,
        pageSize: 10
      }
    },
    {
      blocks: []
    }
  );

  useEffect(() => {
    setRecords((oldRecord) => {
      return oldRecord.concat(
        blockResult.data.blocks.map((item: any) => {
          const {
            timestamp,
            number,
            minerReward,
            txnFees,
            uncleInclusionRewards,
            miner,
            hash
          } = item;
          return {
            time: formatTime(timestamp),
            height: number,
            reward: minerReward,
            fee: formatNumber(txnFees, 8),
            uncleReward: formatNumber(uncleInclusionRewards, 8),
            address: miner,
            hash
          };
        })
      );
    });
  }, [blockResult.data.blocks]);

  return (
    <>
      <BaseInfo dataList={baseInfoList} />
      <NetCalc data={chartResult.data} isLoading={chartResult.isLoading} />
      <BlockRecords
        records={records}
        isLoading={blockResult.isLoading}
        addressLabel={'矿工地址'}
        loadMore={() => {
          page.current = page.current + 1;
          doFetch({
            url: DEFAULT_CONFIG.EXPlORER_BASE_URL + '/api/block/page',
            params: {
              pageNumber: page.current,
              pageSize: 10
            }
          });
        }}
      />
    </>
  );
}
