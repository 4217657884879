import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import BaseInfo, { BaseInfoItem } from '@/components/BaseInfo';
import PoolCalc from '@/components/PoolCalc';
import BlockRecords, { RecordItem } from '@/components/BlockRecords';
import { useDataApi } from '../api';
import { formatHash, formatNumber, formatTime } from '../utils';

export default function PoolInfo() {
  const netInfo = useSelector((state: any) => state.netInfo);
  const { curCalc, totalCount } = netInfo;
  const [baseInfoList, setBaseInfoList] = useState<BaseInfoItem[]>([
    {
      label: '当前矿池算力',
      key: 'curCalc',
      value: null,
      render: (val) => formatHash(Number(val))
    },
    {
      label: '算力占比',
      key: 'curDiff',
      value: null
    },
    {
      label: '矿池累计出块数',
      key: 'totalCount',
      value: null
    },
    {
      label: '出块占比',
      key: 'aveTime',
      value: null
    }
  ]);

  useEffect(() => {
    if (totalCount) {
      setBaseInfoList((oldBaseInfo: BaseInfoItem[]) => {
        const newData = oldBaseInfo.slice();
        const poolBlockCounts = oldBaseInfo[2].value;
        if (typeof poolBlockCounts === 'number') {
          newData[3].value =
            ((poolBlockCounts / totalCount) * 100).toFixed(2) + '%';
        }
        return newData;
      });
    }
    if (curCalc) {
      setBaseInfoList((oldBaseInfo: BaseInfoItem[]) => {
        const newData = oldBaseInfo.slice();
        const poolCalc = oldBaseInfo[0].value;
        if (typeof poolCalc === 'number') {
          newData[1].value = ((poolCalc / curCalc) * 100).toFixed(2) + '%';
        }
        return newData;
      });
    }
  }, [curCalc, totalCount]);

  const [chartData] = useDataApi(
    {
      url: '/ims/pool/hash'
    },
    []
  );
  useEffect(() => {
    if (chartData.data.length) {
      setBaseInfoList((oldBaseInfo: BaseInfoItem[]) => {
        const newData = oldBaseInfo.slice();
        newData[0].value = chartData.data[chartData.data.length - 1].hashrate;
        return newData;
      });
    }
  }, [chartData.data]);

  const page = useRef(1);
  const [records, setRecords] = useState<RecordItem[]>([]);
  const [recordsRes, doFetch] = useDataApi(
    {
      url: '/ims/pool/blockRecord',
      params: {
        page: page.current,
        pageSize: 10
      }
    },
    {
      txs: [],
      count: null
    }
  );

  useEffect(() => {
    setRecords((oldRecord) => {
      return oldRecord.concat(
        recordsRes.data.txs.map((item: any) => {
          const {
            timestamp,
            number,
            minerReward,
            txnFees,
            uncleInclusionRewards,
            hash
          } = item;
          return {
            time: formatTime(timestamp),
            height: number,
            reward: minerReward,
            fee: formatNumber(txnFees, 8),
            uncleReward: formatNumber(uncleInclusionRewards, 8),
            address: hash
          };
        })
      );
    });
  }, [recordsRes.data.txs]);

  useEffect(() => {
    setBaseInfoList((oldBaseInfo: BaseInfoItem[]) => {
      const newData = oldBaseInfo.slice();
      newData[2].value = recordsRes.data.count;
      return newData;
    });
  }, [recordsRes.data.count]);

  return (
    <>
      <BaseInfo dataList={baseInfoList} />
      <PoolCalc data={chartData.data} isLoading={chartData.isLoading} />
      <BlockRecords
        records={records}
        isLoading={recordsRes.isLoading}
        addressLabel={'区块哈希'}
        loadMore={() => {
          page.current = page.current + 1;
          doFetch({
            url: '/ims/pool/blockRecord',
            params: {
              page: page.current,
              pageSize: 10
            }
          });
        }}
      />
    </>
  );
}
