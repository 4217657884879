import { Dispatch, SetStateAction, useState, useEffect } from 'react';
import axios, { AxiosRequestConfig, AxiosError } from 'axios';
import { store } from '@/index';
import { UserInfo } from '@/store/store.d';
// import DEAULT_CONFIG from '../config/config';

export const instance = axios.create({
  // baseURL: DEAULT_CONFIG.API_BASE_URL,
  timeout: 600000
});

/* respone拦截器 */
// instance.interceptors.response.use(
//   (response: AxiosResponse) => {
//     return response.data;
//   },
//   (error: any) => {
//     return Promise.reject(error);
//   }
// );

/* request拦截器 */
instance.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    const { userInfo }: { userInfo: UserInfo } = store.getState();
    if (userInfo.token) {
      config.headers.Authorization = 'Bearer ' + userInfo.token;
    }
    return config;
  },
  (error: AxiosError) => {
    Promise.reject(error);
  }
);

interface IResult<T> {
  data: any;
  isLoading: boolean;
  isError: boolean;
}

interface ParamsConfig extends AxiosRequestConfig {
  cancelFetch?: boolean;
}

export const useDataApi = <T extends any>(
  initialFetchParams: ParamsConfig,
  initialData: any
): [IResult<T>, Dispatch<SetStateAction<ParamsConfig>>] => {
  const [data, setData] = useState(initialData);

  const [fetchParams, setFetchParams] = useState(initialFetchParams);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    let didCancel = false;
    const fetchData = async () => {
      setIsError(false);
      setIsLoading(true);
      try {
        const result = await instance({
          method: 'get',
          ...fetchParams
        });
        // 如果组件被销毁，则无需再设置state
        if (!didCancel) {
          setIsLoading(false);
          setData(result.data);
        }
      } catch (error) {
        if (!didCancel) {
          setIsLoading(false);
          setIsError(true);
        }
      }
    };
    if (!fetchParams.cancelFetch) {
      fetchData();
    }
    return () => {
      // 组件被销毁时，设置didCancel为true
      didCancel = true;
    };
  }, [fetchParams]);
  return [{ data, isLoading, isError }, setFetchParams];
};
