import { MessageInfo, MessageAction } from '../store.d';
const initialState: MessageInfo = {
  message: '',
  open: false
};

export default function netInfo(state = initialState, action: MessageAction) {
  const { data } = action;
  switch (action.type) {
    case 'UPDATE_MESSAGE_INFO': {
      const newState = {
        ...state,
        ...data
      };
      return newState;
    }
    default:
      return state;
  }
}
