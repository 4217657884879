import { UserInfo, UserAction } from '../store.d';
const spUser: string | null = localStorage.getItem('SIMPOOL_UID');
const initialState: UserInfo = spUser ? JSON.parse(spUser) : {};

export default function userInfo(state = initialState, action: UserAction) {
  const { data } = action;
  switch (action.type) {
    case 'ADD_USER': {
      const newState = {
        ...state,
        ...data
      };
      localStorage.setItem('SIMPOOL_UID', JSON.stringify(newState));
      return newState;
    }
    case 'DELETE_USER': {
      const newState = {} as UserInfo;
      localStorage.removeItem('SIMPOOL_UID');
      return newState;
    }
    case 'SWITCH_MINER': {
      const newState = {
        ...state,
        curMiner: data
      };
      localStorage.setItem('SIMPOOL_UID', JSON.stringify(newState));
      return newState;
    }
    default:
      return state;
  }
}
