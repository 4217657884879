import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import clsx from 'clsx';
import DEAULT_CONFIG from './config/config';
import { useDataApi } from './api';
import { updateInfo } from '@/store/actions';

import PoolInfo from './pages/PoolInfo';
import NetInfo from './pages/NetInfo';
import AccountInfo from './pages/AccountInfo';

const ReconnectingWebSocket: any = require('reconnectingwebsocket');

const useStyles = makeStyles((theme: Theme) => {
  // console.log(theme);
  return {
    app: {
      color: '#000'
    },
    header: {
      display: 'flex',
      borderBottom: '1px solid #F2F2FC'
    },
    tabItem: {
      position: 'relative',
      display: 'block',
      flex: 1,
      border: 0,
      outline: 'none',
      backgroundColor: 'transparent',
      padding: theme.typography.pxToRem(32),
      color: theme.palette.text.secondary,
      '&.active': {
        color: '#1a1a1a',
        '&:after': {
          width: theme.typography.pxToRem(40)
        }
      },
      '&:after': {
        content: '""',
        position: 'absolute',
        bottom: 0,
        left: '50%',
        transform: 'translateX(-50%)',
        width: 0,
        height: theme.typography.pxToRem(6),
        borderRadius: theme.typography.pxToRem(3),
        backgroundColor: theme.palette.primary.main,
        transition: 'width 0.3s ease-out'
      }
    }
  };
});

function App() {
  const classes = useStyles();
  const [tabs] = useState([
    {
      name: '账户信息',
      id: 1
    },
    {
      name: '矿池信息',
      id: 2
    },
    {
      name: '全网信息',
      id: 3
    }
  ]);
  const [activeTab, setActiveTab] = useState(1);

  const [{ data }] = useDataApi(
    {
      url: '/ims/sipcDiffculty'
    },
    {
      difficulty: null,
      number: null
    }
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      updateInfo({
        curDiff: data.difficulty,
        totalCount: data.number
      })
    );
  }, [data, dispatch]);

  useEffect(() => {
    const ws = new ReconnectingWebSocket(
      `${DEAULT_CONFIG.WS_URL}/?_primuscb=${new Date().getTime()}-0`
    );

    ws.onopen = function () {
      console.log('连接打开');
      ws.send(JSON.stringify({ emit: ['ready'] }));
    };

    ws.onmessage = function (evt: MessageEvent) {
      const msg = JSON.parse(evt.data);
      switch (msg.action) {
        case 'block':
          const { number, difficulty } = msg.data.block;
          dispatch(
            updateInfo({
              curDiff: difficulty,
              totalCount: number
            })
          );
          break;
        case 'charts':
          const { avgBlocktime, avgHashrate } = msg.data;
          dispatch(
            updateInfo({
              curCalc: avgHashrate,
              aveTime: avgBlocktime
            })
          );
          break;
        case 'client-ping':
          ws.send(
            JSON.stringify({
              emit: [
                'client-pong',
                {
                  serverTime: msg.data.serverTime,
                  clientTime: new Date().getTime()
                }
              ]
            })
          );
          break;
      }
    };

    ws.onclose = function () {
      console.log('连接关闭');
    };
    window.onbeforeunload = function () {
      ws.close();
    };
    return () => ws.close();
  }, [dispatch]);

  return (
    <div className={classes.app}>
      <div className={classes.header}>
        {tabs.map((tab) => (
          <button
            className={clsx(classes.tabItem, {
              active: activeTab === tab.id
            })}
            key={tab.id}
            onClick={() => {
              setActiveTab(tab.id);
            }}
          >
            <Typography variant="h3" component="span">
              {tab.name}
            </Typography>
          </button>
        ))}
      </div>
      <div>
        {activeTab === 1 ? (
          <AccountInfo />
        ) : activeTab === 2 ? (
          <PoolInfo />
        ) : (
          <NetInfo />
        )}
      </div>
    </div>
  );
}

export default App;
