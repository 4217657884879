import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import ActionButton from '@/components/ActionButton';
import { updateMessage, addUser } from '@/store/actions';
import { instance } from '@/api';

const useStyles = makeStyles((theme: Theme) => ({
  loginRoot: {
    padding: `${theme.typography.pxToRem(110)} ${theme.typography.pxToRem(
      48
    )} ${theme.typography.pxToRem(38)}`
  },
  spLogo: {
    display: 'block',
    margin: `0 auto ${theme.typography.pxToRem(72)}`,
    width: theme.typography.pxToRem(152),
    height: theme.typography.pxToRem(152)
  },
  formContainer: {
    marginBottom: theme.typography.pxToRem(72)
  },
  loginBtn: {
    marginBottom: theme.typography.pxToRem(48),
    backgroundColor: 'rgba(4, 21, 39, 1)',
    '&:hover': {
      backgroundColor: '#212121'
    }
  },
  inputLabel: {
    color: '#999',
    fontSize: theme.typography.pxToRem(32)
  },
  inputLabelShrink: {
    transform: `translate(0, 6.5px) scale(0.75)`
  },
  input: {
    padding: `${theme.typography.pxToRem(42)} 0`,
    fontSize: theme.typography.pxToRem(32),
    lineHeight: 1.5
  },
  inputUnderline: {
    '&:before': {
      borderBottom: '1px solid #EBEBEB'
    },
    '&:hover:not(.Mui-disabled):before': {
      borderBottom: '1px solid #EBEBEB'
    }
  }
}));

export default function LoginView() {
  const classes = useStyles();
  const [loginForm, setLoginForm] = useState({
    phone: '',
    password: ''
  });
  const dispatch = useDispatch();
  const handleLoginBtnClick = () => {
    checkFormValidate()
      .then(() => {
        // setIsLoading(true);
        instance({
          method: 'post',
          url: '/ims/login',
          data: loginForm
        }).then((res) => {
          if (res.data.error) {
            dispatch(
              updateMessage({
                message: '用户名或密码不正确',
                open: true
              })
            );
          } else {
            dispatch(
              addUser({
                account: res.data.phone,
                token: res.data.token
              })
            );
            setTimeout(() => {
              instance({
                method: 'post',
                url: '/ims/account/all'
              }).then((accountRes) => {
                if (accountRes.data.error) {
                  dispatch(
                    updateMessage({
                      message: '获取用户信息失败',
                      open: true
                    })
                  );
                } else {
                  const minerList = Object.keys(accountRes.data).map((key) => ({
                    id: accountRes.data[key].id as number,
                    name: key
                  }));
                  dispatch(
                    addUser({
                      curMiner: minerList[0],
                      minerList
                    })
                  );
                }
              });
            }, 100);
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const checkFormValidate = () => {
    return new Promise((resolve, reject) => {
      if (
        !/^[1](([3][0-9])|([4][5-9])|([5][0-3,5-9])|([6][5,6])|([7][0-8])|([8][0-9])|([9][1,8,9]))[0-9]{8}$/.test(
          loginForm.phone
        )
      ) {
        dispatch(
          updateMessage({
            message: '请输入正确的手机号',
            open: true
          })
        );
        reject();
      }
      if (loginForm.password === '') {
        dispatch(
          updateMessage({
            message: '请输入密码',
            open: true
          })
        );
        reject();
      }
      resolve();
    });
  };

  return (
    <div className={classes.loginRoot}>
      <img
        className={classes.spLogo}
        src={require('@/assets/images/simpool_icon@2x.png')}
        alt=""
      />
      <div className={classes.formContainer}>
        <TextField
          InputLabelProps={{
            classes: {
              root: classes.inputLabel,
              shrink: classes.inputLabelShrink
            }
          }}
          InputProps={{
            classes: {
              input: classes.input,
              underline: classes.inputUnderline
            }
          }}
          inputProps={{
            placeholder: '请输入手机号'
          }}
          fullWidth
          error={false}
          onChange={(e) => {
            setLoginForm({
              ...loginForm,
              phone: e.target.value
            });
          }}
        />
        <TextField
          InputLabelProps={{
            classes: {
              root: classes.inputLabel,
              shrink: classes.inputLabelShrink
            }
          }}
          InputProps={{
            classes: {
              input: classes.input,
              underline: classes.inputUnderline
            }
          }}
          inputProps={{
            placeholder: '请输入密码'
          }}
          type="password"
          autoComplete="new-password"
          fullWidth
          onChange={(e) => {
            setLoginForm({
              ...loginForm,
              password: e.target.value
            });
          }}
        />
      </div>
      <ActionButton
        classes={{
          root: classes.loginBtn
        }}
        disabled={loginForm.phone === '' || loginForm.password === ''}
        onClick={handleLoginBtnClick}
      >
        直接登录
      </ActionButton>
      {/* <ActionButton>SIPC.VIP 授权登录</ActionButton> */}
    </div>
  );
}
