import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Block from '@/components/Block';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { useDataApi } from '@/api';
import { formatNumber, formatDate } from '@/utils';

const windowHeight = window.innerHeight;

interface RecordsInfo {
  key: string;
  label: string;
  formatter?: {
    (val: any): string | React.ReactNode;
  };
  fullWidth?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    display: 'flex'
  },
  tabItem: {
    position: 'relative',
    display: 'block',
    border: 0,
    outline: 'none',
    backgroundColor: 'transparent',
    padding: theme.typography.pxToRem(32),
    color: theme.palette.text.secondary,
    '&.active': {
      color: '#1a1a1a',
      '&:after': {
        width: theme.typography.pxToRem(40)
      }
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: '50%',
      transform: 'translateX(-50%)',
      width: 0,
      height: theme.typography.pxToRem(6),
      borderRadius: theme.typography.pxToRem(3),
      backgroundColor: theme.palette.primary.main,
      transition: 'width 0.3s ease-out'
    }
  },
  recordBlock: {
    position: 'relative',
    display: 'flex',
    flexWrap: 'wrap',
    padding: `${theme.typography.pxToRem(40)} ${theme.typography.pxToRem(
      36
    )} 0`,
    marginBottom: theme.typography.pxToRem(20),
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      left: '-50%',
      top: '-50%',
      width: '200%',
      height: '200%',
      border: '1px solid #F2F2F2',
      transform: 'scale(0.5)',
      borderRadius: 6
    }
  },
  recordItem: {
    width: '33.33333%',
    marginBottom: theme.typography.pxToRem(55),
    '&:nth-child(3n+1)': {
      textAlign: 'left'
    },
    '&:nth-child(3n+2)': {
      textAlign: 'center'
    },
    '&:nth-child(3n)': {
      textAlign: 'right'
    },
    '&$fullWidth': {
      width: '100%',
      textAlign: 'left',
      '& $value': {
        wordBreak: 'break-all',
        lineHeight: 1.5
      }
    }
  },
  value: {
    marginTop: theme.typography.pxToRem(34),
    fontWeight: 400
  },
  fullWidth: {},
  noDataContainer: {
    padding: theme.typography.pxToRem(100),
    textAlign: 'center'
  },
  noDataImg: {
    width: theme.typography.pxToRem(190),
    marginBottom: theme.typography.pxToRem(40)
  },
  noDataLabel: {
    fontSize: theme.typography.pxToRem(26)
  }
}));

export default function Records() {
  const [tabs] = useState([
    {
      name: '收益记录',
      id: 1
    },
    {
      name: '支付记录',
      id: 2
    }
  ]);
  const [activeTab, setActiveTab] = useState(1);

  const classes = useStyles();
  return (
    <Block
      renderHeader={() => (
        <div className={classes.header}>
          {tabs.map((tab) => (
            <button
              className={clsx(classes.tabItem, {
                active: activeTab === tab.id
              })}
              key={tab.id}
              onClick={() => {
                setActiveTab(tab.id);
              }}
            >
              <Typography variant="h3" component="span">
                {tab.name}
              </Typography>
            </button>
          ))}
        </div>
      )}
    >
      {activeTab === 1 ? <RewardRecords /> : <PayRecords />}
    </Block>
  );
}

function RewardRecords() {
  interface RewardRecordsItem {
    timestamps: string;
    refuseRate: number;
    reward: number;
    receiveValue: number;
    refuseValue: number;
  }

  const ref = useRef(null);
  const [records, setRecords] = useState<RewardRecordsItem[]>([]);
  const classes = useStyles();
  const userInfo = useSelector((state: any) => state.userInfo);

  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);

  const recordsInfo: RecordsInfo[] = [
    {
      key: 'timestamps',
      label: '结算时间',
      formatter: (val) => {
        return val.substr(5, 11);
      }
    },
    {
      key: 'refuseRate',
      label: '拒绝率',
      formatter: (val) => {
        return typeof val === 'number' ? `${val}%` : '--';
      }
    },
    {
      key: 'reward',
      label: '收益数量',
      formatter: (val) => (
        <Typography
          variant="subtitle2"
          component="span"
          color="primary"
          className={classes.value}
        >
          {formatNumber(val)}
        </Typography>
      )
    },
    {
      key: 'receiveValue',
      label: '接收数',
      formatter: (val) => {
        return typeof val === 'number' ? val : '--';
      }
    },
    {
      key: 'refuseValue',
      label: '拒绝数',
      formatter: (val) => {
        return typeof val === 'number' ? val : '--';
      }
    }
  ];

  const [{ data, isLoading }, setParams] = useDataApi(
    {
      url: '/ims/reward',
      method: 'post',
      data: {
        name: userInfo.curMiner.name,
        pageNumber: page,
        pageSize: 10
      },
      cancelFetch: true
    },
    undefined
  );

  useEffect(() => {
    if (userInfo.curMiner.name) {
      setPage(1);
      setCount(0);
      setRecords([]);
      setParams((oldParams) => ({
        ...oldParams,
        data: {
          ...oldParams.data,
          name: userInfo.curMiner.name,
          pageNumber: 1
        },
        cancelFetch: false
      }));
    }
  }, [userInfo.curMiner, setParams]);

  useEffect(() => {
    console.log(data);
    if (data && data.data.rows) {
      setCount(data.data.count);
      setRecords((oldRecords: RewardRecordsItem[]) => {
        return [...oldRecords, ...(data.data.rows || [])];
      });
    }
  }, [data]);

  useEffect(() => {
    const scrollFunc = () => {
      if (isLoading || count === 0) {
        return false;
      }
      const lastRecord = ReactDOM.findDOMNode(ref.current)
        ?.lastChild as HTMLDivElement;
      if (
        lastRecord &&
        window.pageYOffset + windowHeight >
          lastRecord.offsetTop - lastRecord.offsetHeight
      ) {
        if (10 * page >= count) {
          console.log(1111);
          window.removeEventListener('scroll', scrollFunc);
        } else {
          setPage(page + 1);
          setParams((oldParams) => {
            return {
              ...oldParams,
              data: {
                ...oldParams.data,
                pageNumber: page + 1
              },
              cancelFetch: false
            };
          });
        }
      }
    };
    window.addEventListener('scroll', scrollFunc);
    return () => window.removeEventListener('scroll', scrollFunc);
  }, [isLoading, page, count, setParams]);

  return (
    <div ref={ref}>
      {!isLoading && records.length === 0 ? (
        <div className={classes.noDataContainer}>
          <img
            className={classes.noDataImg}
            src={require('@/assets/images/no_data@3x.png')}
            alt=""
          />
          <Typography color="textSecondary" className={classes.noDataLabel}>
            暂无收益记录
          </Typography>
        </div>
      ) : (
        records.map((record: any, index) => (
          <div className={classes.recordBlock} key={index}>
            <Grid container direction="row">
              {recordsInfo.map((item) => (
                <div className={classes.recordItem} key={item.key}>
                  <Typography color="textSecondary">{item.label}</Typography>
                  <Typography
                    variant="subtitle2"
                    component="p"
                    className={classes.value}
                  >
                    {item.formatter
                      ? item.formatter(record[item.key])
                      : record[item.key]}
                  </Typography>
                </div>
              ))}
            </Grid>
          </div>
        ))
      )}
    </div>
  );
}

function PayRecords() {
  interface PayRecordsItem {
    updatedAt: string;
    type: 0 | 1 | 2;
    amount: number;
    fee: number;
    status: 0 | 1 | 2 | 3;
    address: string;
  }

  const payType = new Map([
    [0, '挖矿收益'],
    [1, '注册奖励'],
    [2, '好友邀请活动奖励']
  ]);
  const statusMap = new Map([
    [0, '申请中'],
    [1, '支付中'],
    [2, '成功'],
    [3, '失败']
  ]);

  const ref = useRef(null);
  const [records, setRecords] = useState<PayRecordsItem[]>([]);
  const classes = useStyles();
  const userInfo = useSelector((state: any) => state.userInfo);

  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);

  const recordsInfo: RecordsInfo[] = [
    {
      key: 'updatedAt',
      label: '确认时间',
      formatter: (val) => {
        return formatDate(new Date(val));
      }
    },
    {
      key: 'type',
      label: '类型',
      formatter: (val) => {
        return payType.get(val);
      }
    },
    {
      key: 'amount',
      label: '支付数量',
      formatter: (val) => (
        <Typography
          variant="subtitle2"
          component="span"
          color="primary"
          className={classes.value}
        >
          {formatNumber(val)}
        </Typography>
      )
    },
    {
      key: 'fee',
      label: '手续费',
      formatter: (val) => formatNumber(val)
    },
    {
      key: 'status',
      label: '状态',
      formatter: (val) => statusMap.get(val)
    },
    {
      key: 'address',
      label: '支付地址',
      fullWidth: true
    }
  ];

  const [{ data, isLoading }, setParams] = useDataApi(
    {
      url: '/ims/payment',
      method: 'post',
      data: {
        accountId: userInfo.curMiner.id,
        pageNumber: page,
        pageSize: 10
      },
      cancelFetch: true
    },
    undefined
  );

  useEffect(() => {
    if (userInfo.curMiner.id) {
      setPage(1);
      setCount(0);
      setRecords([]);
      setParams((oldParams) => ({
        ...oldParams,
        data: {
          ...oldParams.data,
          pageNumber: 1,
          accountId: userInfo.curMiner.id
        },
        cancelFetch: false
      }));
    }
  }, [userInfo.curMiner, setParams]);

  useEffect(() => {
    console.log(data);
    if (data) {
      setCount(data.data.count);
      setRecords((oldRecords: PayRecordsItem[]) => {
        return [...oldRecords, ...(data.data.txs || [])];
      });
    }
  }, [data]);

  useEffect(() => {
    const scrollFunc = () => {
      if (isLoading || count === 0) {
        return false;
      }
      const lastRecord = ReactDOM.findDOMNode(ref.current)
        ?.lastChild as HTMLDivElement;
      if (
        lastRecord &&
        window.pageYOffset + windowHeight >
          lastRecord.offsetTop - lastRecord.offsetHeight
      ) {
        if (10 * page >= count) {
          console.log(1111);
          window.removeEventListener('scroll', scrollFunc);
        } else {
          setPage(page + 1);
          setParams((oldParams) => {
            return {
              ...oldParams,
              data: {
                ...oldParams.data,
                pageNumber: page + 1
              },
              cancelFetch: false
            };
          });
        }
      }
    };
    window.addEventListener('scroll', scrollFunc);
    return () => window.removeEventListener('scroll', scrollFunc);
  }, [isLoading, page, count, setParams]);

  return (
    <div ref={ref}>
      {!isLoading && records.length === 0 ? (
        <div className={classes.noDataContainer}>
          <img
            className={classes.noDataImg}
            src={require('@/assets/images/no_data@3x.png')}
            alt=""
          />
          <Typography color="textSecondary" className={classes.noDataLabel}>
            暂无支付记录
          </Typography>
        </div>
      ) : (
        records.map((record: any, index) => (
          <div className={classes.recordBlock} key={index}>
            <Grid container direction="row">
              {recordsInfo.map((item) => (
                <div
                  className={clsx(
                    classes.recordItem,
                    item.fullWidth ? classes.fullWidth : null
                  )}
                  key={item.key}
                >
                  <Typography color="textSecondary">{item.label}</Typography>
                  <Typography
                    variant="subtitle2"
                    component="p"
                    className={classes.value}
                  >
                    {item.formatter
                      ? item.formatter(record[item.key])
                      : record[item.key]}
                  </Typography>
                </div>
              ))}
            </Grid>
          </div>
        ))
      )}
    </div>
  );
}
